import { React } from 'react';
import background from "../images/Feature2.png";
import { makeStyles } from '@mui/styles';
import "./Test.css";

const useStyles = makeStyles(() => ({
    contactBg: {
        backgroundImage: `url(${background})`,
        minHeight: '100vh',
        backgroundPosition: 'start',
        backgroundSize: 'cover',
        color: 'white',
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center'
      }
}))
function Feature2() {
    const classes = useStyles()
    return <>
    <div className={classes.contactBg}>
        <div className="featureContainer">
            <div className="f2Header">Our Breakdown</div>
            <div className="f2Box">
                <div className="f2InfoHead">Sales Commissions</div>
                <div className="f2InfoText">Streamline communication through in-app messaging, instant product sharing, and easy contact organization</div>
            </div>
            <div className="f2Box">
                <div className="f2InfoHead">Maintaining Contacts</div>
                <div className="f2InfoText">Simplifying commission payments with automatic incentive calculations and secure transactions using cryptocurrency and blockchain</div>
            </div>
            <div className="f2Box">
                <div className="f2InfoHead">Growing Networks</div>
                <div className="f2InfoText">Grow revenue and sales by expanding networks and encouraging cross-industry collaboration</div>
            </div>
        </div>
    </div>
    </>
}

export default Feature2;