import { React } from 'react';
import background from "../images/Feature4.png";
import { makeStyles } from '@mui/styles';
import "./Test.css";

const useStyles = makeStyles(() => ({
    contactBg: {
        backgroundImage: `url(${background})`,
        minHeight: '100vh',
        backgroundPosition: 'start',
        backgroundSize: 'cover',
        color: 'white',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        alignItems: 'center'
      }
}))
function Feature4() {
    const classes = useStyles()
    return <>
    <div className={classes.contactBg}>
        <div className='f4InfoHead'>Numbers</div>
        <div className='f4Nums'>
            <div className='f4InfoNumBox'>
                <div className='f4InfoPoint'>20~30%</div>
                <div className='f4InfoText'>Average Commission Rate for Sales</div>
            </div>
            <div className='f4InfoNumBox'>
                <div className='f4InfoPoint'>70%</div>
                <div className='f4InfoText'>Percentage of Revenue Driven by Distributors & Resellers</div>
            </div>
            <div className='f4InfoNumBox'>
                <div className='f4InfoPoint'>60 : 40</div>
                <div className='f4InfoText'>Commission Structure on Average</div>
            </div>
            <div className='f4InfoNumBox'>
                <div className='f4InfoPoint'>40%</div>
                <div className='f4InfoText'>Average Sales Performance Increase with Working Incentives</div>
            </div>
        </div>
    </div>
    </>
}

export default Feature4;