import { Container } from '@mui/material';
import { React } from 'react';
import "./Test.css";
import Logo from '../images/INPAYTO LOGO-black-02.png';
import Apple from '@mui/icons-material/Apple';
import Shop from '@mui/icons-material/Shop';
import { Link } from 'react-router-dom';

const Footer = () => {

    return (
        <>
        <Container className="footer-container" maxWidth="false" sx={{ display: 'flex' }}>
            <div className="footer-info">
                <img src={Logo} style={{ height: "20px", width: "auto", marginRight: "20px" }} alt="Inpayto Logo"/>
                <a href="https://apps.apple.com/tw/app/inpayto/id1585351446?l=en" target="_blank" rel='noreferrer'><Apple className="foot_download_button"></Apple></a>
                <div className="bar"></div>
                <a href="https://play.google.com/store/apps/details?id=com.inpayto&hl=en_US&gl=US" target="_blank" rel='noreferrer'><Shop className="foot_download_button"></Shop></a>
            </div>
            <hr style={{ width: "100%" }} />
            <div className="footer-info">
                <div style={{ marginRight: "30px" }}>Copyright &copy; 2022 Inpayto INC</div>
                <Link to="/terms" className="footerLink">Terms & Services</Link>
                <div className="bar"></div>
                <Link to="/privacy" style={{ margin: "0px", padding: "0px" }} className="footerLink">Privacy Policy</Link>
            </div>
        </Container>
        </>
    )
}

export default Footer;