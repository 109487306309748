import './App.css';
import Contact from './components/Contact';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Product from './components/Product';
import { makeStyles } from '@mui/styles';
import Footer from './components/Footer';
import { Route, Routes } from 'react-router-dom';
import { useState } from 'react';
import Terms from './components/Terms';
import Privacy from './components/Privacy';

const useStyles = makeStyles(() => ({
  nav: {
    zIndex: '999'
  }
}))

function App() {
  const [state, setState] = useState(0)
  const classes = useStyles()
  return ( 
    <>
    <Navbar state={state} className={classes.nav}/>

    <Routes>
      <Route path="/" element={
        <>
        <Home setState={setState}/>
        <Product />
        <Contact />
        </>
      }/>
      
      <Route path="/terms" element={ <Terms setState={setState}/> } />
      <Route path="/privacy" element={ <Privacy setState={setState}/> } />
    </Routes>

    <Footer />
    </>
  );
}

export default App;
