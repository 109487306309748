import { Grid } from '@mui/material';
import { React, useEffect } from 'react';
import background from "../images/inpayto_banner_bg.png";
import { makeStyles } from '@mui/styles';
import "./Test.css";
import ShopIcon from '@mui/icons-material/Shop';
import AppleIcon from '@mui/icons-material/Apple';

const useStyles = makeStyles(() => ({
    homeBg: {
        backgroundImage: `url(${background})`,
        minHeight: '102vh',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        color: 'white',
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        padding: '0px 20px'
      }
}))

function Home(props) {
    const classes = useStyles()

    useEffect(() => {
        props.setState(0)
    })

    return <div className={classes.homeBg} id="home">
        <Grid container spacing={2}>
            <Grid className="logo_links_box" item xs={12} md={6}>
                <div className="download_box"></div>
                <div className="slogan">
                    <div className="bannerText">A blockchain based, social network wallet for vendors and distributors</div>
                </div>
                <div className="download_box">
                    <div className="download">Download</div>
                    <div>
                        <a href="https://apps.apple.com/tw/app/inpayto/id1585351446?l=en" target="_blank" rel='noreferrer'><AppleIcon className="download_button" sx={{ fontSize: 60 }}></AppleIcon></a>
                        <a href="https://play.google.com/store/apps/details?id=com.inpayto&hl=en_US&gl=US" target="_blank" rel='noreferrer'><ShopIcon className="download_button" sx={{ fontSize: 60 }}></ShopIcon></a>
                    </div>
                </div>
            </Grid>
        </Grid>
    </div>
}

export default Home;