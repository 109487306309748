import { React } from 'react';
import background from "../images/Feature1.png";
import { makeStyles } from '@mui/styles';
import "./Test.css";

const useStyles = makeStyles(() => ({
    contactBg: {
        backgroundImage: `url(${background})`,
        minHeight: '100vh',
        backgroundPosition: 'start',
        backgroundSize: 'cover',
        color: 'white',
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        padding: '0px 20px'
      }
}))
function Feature1() {
    const classes = useStyles()
    return <>
    <div className={classes.contactBg}>
        <div className="featureContainer">
            <div className="f1InfoBox">
                <div className="f1InfoTab"><div>Problem</div></div>
                <div className="f1InfoText">There is no current infrastructure that allows sellers to reliably manage and find distributors for their products.</div>
            </div>

            <div className="f1InfoBox">
                <div className="f1InfoTab"><div>Solution</div></div>
                <div className="f1InfoText">A platform that automates, organizes, and elevates the sales distribution and commission process by concentrating the needs of both sellers and distributors.</div>
            </div>
        </div>
    </div>
    </>
}

export default Feature1;