import { React } from 'react';
import Feature1 from './Feature1';
import Feature2 from './Feature2';
// import Feature3 from './Feature3';
import Feature4 from './Feature4';

function Product() {
    return (
        <>
            <span className="anchor" id="product"></span>
            <Feature1 />
            <Feature2 />
            {/* <Feature3 /> */}
            <Feature4 />
        </>
    )
}

export default Product;