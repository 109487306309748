import { Container } from '@mui/material';
import { React } from 'react';
import background from "../images/in_contact_bg.png";
import { makeStyles } from '@mui/styles';
import "./Test.css";

const useStyles = makeStyles(() => ({
    contactBg: {
        backgroundImage: `url(${background})`,
        height: '100vh',
        backgroundPosition: 'start',
        backgroundSize: 'cover',
        color: 'white',
        width: '100vw',
      }
}))
function Contact() {
    const classes = useStyles()
    return <>
    <span className="anchor_contact" id="contact"></span>
    <Container maxWidth="false" className={classes.contactBg}>
        <div className="formContainer">
        <div className="form">
            <h2 style={{ marginTop: "20px" }}>Contact</h2>
            <div className="contact-info">
                <div>
                    <div className='info_text'>Email: info@inpayto.com</div>
                    <div className='line'></div>
                </div>
                <div>
                    <div className='info_text'>Phone: +1 (650) 398-7549</div>
                    <div className='line'></div>
                </div>
                <div>
                    <div className='info_text'>Based in San Jose, California</div>
                    <div className='line'></div>
                </div>
            </div>
        </div>
        </div>
    </Container>
    </>
}

export default Contact;