import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import LogoBlack from '../images/INPAYTO LOGO-black-01.png';
import { Link } from 'react-router-dom';

const pages = ['Inpayto', 'Product', 'Contact'];
const links = ['#home', '#product', '#contact'];

const Navbar = (props) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };


  return (
    <AppBar position="fixed" sx={{ color: 'black', backgroundColor: 'white'}}>
      <Container maxWidth="false">
        <Toolbar disableGutters sx={{ color: 'black'}}>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
          >
            { props.state === 0 ?
            <img src={LogoBlack} alt="Inpayto Logo" style={{ width: "200px", height: "auto"}} /> 
            : <Link to="/" className="routeLink">
              <img src={LogoBlack} alt="Inpayto Logo" style={{ width: "200px", height: "auto"}} /> 
            </Link>
            }
          </Typography>

          { props.state === 0 && 
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page, index) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <a 
                  href={links[index]}
                  style={{ textAlign: "center", color: 'black'}}
                  >{page}</a>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          }

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
            { props.state === 0 ?
            <img src={LogoBlack} alt="Inpayto Logo" style={{ width: "200px", height: "auto"}} /> 
            : <Link to="/" className="routeLink">
              <img src={LogoBlack} alt="Inpayto Logo" style={{ width: "200px", height: "auto"}} /> 
            </Link>
            }
          </Typography>

          { props.state === 0 && 
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
              {pages.map((page, index) => (
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'black', display: 'block', margin: '15px' }}
                >
                  <a 
                  href={links[index]}
                  style={{ color: 'black'}}
                  >{page}</a>
                </Button>
              ))}
            </Box> }

          <Box sx={{ display: { xs: 'none', md: 'flex' }, width: "200px" }}></Box>

        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Navbar;